//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { flowableShortcutList } from '@/api/modular/flowable/shortcutManage'
import createForm from './createForm'
export default {
  components: {
    createForm
  },
  data() {
    return {
      shortcutListData: [],
      cardLoading: false,
      indexPageShow: true,
      createFormShow: false
    }
  },
  created() {
    this.cardLoading = true
    flowableShortcutList().then(res => {
      this.cardLoading = false
      this.shortcutListData = res.data
      if (this.shortcutListData.length < 1) {
        this.$message.warning('無入口申請，請在定義管理中添加應用')
      }
    })
  },
  methods: {
    openCreateForm(items) {
      if (items.processDefinitionId == null) {
        this.$message.warning('打開失敗：表單項定義processDefinitionId不存在')
      } else {
        this.indexPageShow = false
        this.createFormShow = true
        this.$refs.createForm.open(items)
      }
    },
    closeCreateForm() {
      this.indexPageShow = true
      this.createFormShow = false
    },
    rollbackCreateForm() {
      this.indexPageShow = true
      this.createFormShow = false
    }
  }
}
